.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.keyboard > button {
  background-color: #282828;
  border: none;
  color: #ebdbb2;

  &:hover {
    animation: pop 0.3s linear;
    transform: scale(1.2);
  }
}

.keyboard-row > button {
  padding: 25px 20px;
  font-size: 18px;
  text-transform: uppercase;

  background-color: #a89984;
  border: 1px solid #282828;
  border-radius: 2px;

  &:hover {
    background-color: #d5c4a1;
    transform: scale(1.1);
  }

  &:disabled {
    background-color: #504945;
  }

  &.green {
    background-color: #8ec07c;
    color: #282828;
  }
  &.red {
    background-color: #af3a03;
    color: #282828;
  }

  &.keyboard-row__hide-btn {
    background-color: #928374;

    &:hover {
      background-color: #a89984;
    }
  }
}
