.stage__wrapper {
  height: 100%;
  display: grid;
  place-items: center;
}

.stage {
  text-align: center;
  font-size: 38px;
  color: #8ec07c;
}
