@import "partials/_keyboard";
@import "partials/_guess";
@import "partials/_hangman";
@import "partials/_incorrect-letters";
@import "partials/_animation";
@import "partials/_stage";

h1 {
  margin-bottom: 0;

  & span {
    display: inline-block;
    animation-name: jump;
    animation-duration: 2s;
    color: #d79921;
  }
}
