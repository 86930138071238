.keyboard {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.keyboard > button {
  color: #ebdbb2;
  background-color: #282828;
  border: none;
}

.keyboard > button:hover {
  animation: .3s linear pop;
  transform: scale(1.2);
}

.keyboard-row > button {
  text-transform: uppercase;
  background-color: #a89984;
  border: 1px solid #282828;
  border-radius: 2px;
  padding: 25px 20px;
  font-size: 18px;
}

.keyboard-row > button:hover {
  background-color: #d5c4a1;
  transform: scale(1.1);
}

.keyboard-row > button:disabled {
  background-color: #504945;
}

.keyboard-row > button.green {
  color: #282828;
  background-color: #8ec07c;
}

.keyboard-row > button.red {
  color: #282828;
  background-color: #af3a03;
}

.keyboard-row > button.keyboard-row__hide-btn {
  background-color: #928374;
}

.keyboard-row > button.keyboard-row__hide-btn:hover {
  background-color: #a89984;
}

.word-to-guess {
  justify-content: center;
  gap: 24px;
  font-size: 64px;
  display: flex;
}

.word-to-guess__letter {
  text-align: center;
  height: 68px;
  width: 82px;
  color: #ebdbb2;
  border-bottom: 1px solid #b8bb26;
}

.word-to-guess__letter.appear {
  opacity: 1;
  transition: opacity .8s cubic-bezier(.5, 1, .5, 1) .4s, transform .8s cubic-bezier(.5, 1, .5, 1) .5s;
  animation: .1s linear .1s pop;
  transform: translateY(-10px);
}

.hangman__game-wrapper {
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
}

.incorrect-letters__wrapper {
  height: 88px;
  width: 80%;
  flex-direction: column;
  margin: 36px auto 0;
  font-size: 58px;
  display: flex;
}

.incorrect-letters__wrapper > p {
  margin: 0;
}

.incorrect-letters__wrapper > pre {
  color: #d65d0e;
  margin-bottom: 0;
}

.incorrect-letters__list {
  color: #d65d0e;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.incorrect-letters__letter {
  animation: .3s linear pop;
}

@keyframes pop {
  50% {
    transform: scale(1.5);
  }
}

@keyframes jump {
  0% {
    color: #b8bb26;
  }

  20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-45px);
  }

  60% {
    transform: translateY(-10px);
  }

  80% {
    color: #b8bb26;
  }
}

.stage__wrapper {
  height: 100%;
  place-items: center;
  display: grid;
}

.stage {
  text-align: center;
  color: #8ec07c;
  font-size: 38px;
}

h1 {
  margin-bottom: 0;
}

h1 span {
  color: #d79921;
  animation-name: jump;
  animation-duration: 2s;
  display: inline-block;
}

/*# sourceMappingURL=index.15e9f045.css.map */
