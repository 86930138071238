.incorrect-letters__wrapper {
  display: flex;
  flex-direction: column;

  font-size: 58px;
  height: 88px;
  width: 80%;
  margin: 36px auto 0;

  > p {
    margin: 0;
  }

  > pre {
    margin-bottom: 0;
    color: #d65d0e;
  }
}

.incorrect-letters__list {
  list-style-type: none;
  color: #d65d0e;
  margin: 0;
  padding: 0;

  display: flex;
  justify-content: center;
}

.incorrect-letters__letter {
  animation: pop 0.3s linear 1;
}
@keyframes pop {
  50% {
    transform: scale(1.5);
  }
}
