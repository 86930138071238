.word-to-guess {
  display: flex;
  justify-content: center;
  gap: 24px;
  font-size: 64px;
}

.word-to-guess__letter {
  border-bottom: 1px solid #b8bb26;
  text-align: center;
  height: 68px;
  width: 82px;
  color: #ebdbb2;

  &.appear {
    opacity: 1;
    transition: opacity 0.8s cubic-bezier(0.5, 1, 0.5, 1),
      transform 0.8s cubic-bezier(0.5, 1, 0.5, 1);
    transition-delay: 0.4s, 0.5s;
    transform: translateY(-10px);
    animation: pop 0.1s linear;
    animation-delay: 0.1s;
  }
}
