@keyframes pop {
  50% {
    transform: scale(1.5);
  }
}

@keyframes jump {
  0% {
    color: #b8bb26;
  }
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-45px);
  }
  60% {
    transform: translateY(-10px);
  }
  80% {
    color: #b8bb26;
  }
}
